import { Link, graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React, { useContext, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button, Modal } from "react-bootstrap";
import Layout from "../components/layout";
import { FaArrowLeftLong } from "react-icons/fa6";
import CartContext from "../context/CartContext";
import StandardLogo from "../images/standard-icon.svg";
import ProLogo from "../images/pro-icon.svg";
import ExecLogo from "../images/exec-icon.svg";
import PopupForm from "../components/popup-form";
import ExpertCardPopup from "../components/expert-card-popup";
import { MdOutlineClear } from "react-icons/md";
import PopupFormCalculator from "../components/popup-form-calculator";

const CalculatorResultsPage = ({ data: { wpCalculatorLocation, site } }) => {
	const {
		title,
		slug,
		calculatorLocationFields: {
			shortLocationName,
			ctaButton,
			card1,
			card2,
			card3,
		},
	} = wpCalculatorLocation;
	const { settingVal, numberOfDesksCalculator } = useContext(CartContext);

	const [formShow, setFormShow] = useState(false);
	const [selectedCard, setSelectedCard] = useState({
		title: "",
		priceFrom: 0,
		priceTo: 0,
	});

	const handleFormShow = (title, priceFrom, priceTo) => {
		setSelectedCard({
			title,
			priceFrom,
			priceTo,
		});
		setFormShow(true);
	};

	const handleFormClose = () => setFormShow(false);

	return (
		<Layout>
			<GatsbySeo
				title={`${shortLocationName} Calculator | Flex Office Solutions`}
				language="en"
				noindex
			/>
			<Modal
				className="w-100 p-0 rounded"
				style={{ zIndex: 99999, backgroundColor: "rgba(0, 0, 0, 0.2)" }}
				size="xl"
				show={formShow}
				onHide={handleFormClose}
				centered
			>
				<div className="position-relative">
					<div
						className="px-3 pt-2 position-absolute end-0 top-0"
						style={{ zIndex: 3 }}
					>
						<MdOutlineClear
							style={{ cursor: "pointer" }}
							className="fs-4"
							onClick={handleFormClose}
						/>
					</div>

					<Modal.Body
						style={{ overflow: "hidden" }}
						className="p-0 w-100 rounded"
						closeButton
					>
						<div className="py-3 px-3 px-md-4 px-lg-5 mb-2 bg-white">
							<Row>
								<Col>
									<h3 className="pb-0">
										Get a tailored report for{" "}
										<span className="text-dark-blue fw-bold">
											{shortLocationName}
										</span>
									</h3>
								</Col>
							</Row>
							<Row>
								<Col>
									<p>
										Send us your contact details to receive a personalised
										report for your company
									</p>
								</Col>
							</Row>
							<PopupFormCalculator
								type={selectedCard.title}
								priceFrom={selectedCard.priceFrom}
								priceTo={selectedCard.priceTo}
								formPropertyName={shortLocationName}
								subject={`Get a tailored report for ${shortLocationName}`}
							/>
						</div>
						<ExpertCardPopup />
					</Modal.Body>
				</div>
			</Modal>

			<Container className="py-5">
				<Row className="text-center mt-4">
					<Col>
						<h2>Calculator Results</h2>
					</Col>
				</Row>
				<Row className="text-center gy-4 my-md-4 mt-2">
					<Col md={6} className="text-md-start">
						<Link
							to="/calculator"
							className="text-decoration-none dark-blue-link"
						>
							<span className="d-inline-flex align-items-center dark-blue-link">
								<FaArrowLeftLong className="me-2" /> Back to calculator
							</span>
						</Link>
					</Col>
					<Col md={6} className="text-md-end">
						<p className="text-med-grey">
							Estimated monthly cost for{" "}
							<strong className="text-primary">
								{numberOfDesksCalculator}
								{numberOfDesksCalculator === 1 ? " desk" : " desks"}
							</strong>{" "}
							in {shortLocationName}:
						</p>
					</Col>
				</Row>
				<Row className="gx-2 gy-3 gx-lg-4">
					<Col md={4}>
						<div
							style={{
								borderRadius: "14px",
								border: "1px solid #00A7A8",
								background: "#E5F6F6",
							}}
							className="card p-3 px-md-2 text-center px-lg-3"
						>
							<h3 className="text-primary fs-3">{card1.heading}</h3>
							<div
								style={{ height: "90px" }}
								className="d-flex justify-content-center align-items-center"
							>
								<img
									className="mx-auto my-2"
									src={StandardLogo}
									alt=""
									style={{ maxHeight: "70px", maxWidth: "80px" }}
								/>
							</div>
							<p>Price from</p>
							<h4>
								£{card1.priceFrom * numberOfDesksCalculator} to £
								{card1.priceTo * numberOfDesksCalculator}
							</h4>
							<Button
								variant="outline-dark-blue"
								className="w-100 w-md-auto btn fw-bold rounded-pill mt-4 px-lg-4 py-2"
								onClick={() =>
									handleFormShow(
										card1.heading,
										card1.priceFrom * numberOfDesksCalculator,
										card1.priceTo * numberOfDesksCalculator
									)
								}
							>
								Request tailored report
							</Button>
						</div>
					</Col>
					<Col md={4}>
						<div
							style={{
								borderRadius: "14px",
								border: "1px solid #0B4982",
								background: "#0B49821A",
							}}
							className="card p-3 px-md-2 text-center px-lg-3"
						>
							<h3 className="text-dark-blue fs-3">{card2.heading}</h3>
							<div
								style={{ height: "90px" }}
								className="d-flex justify-content-center align-items-center"
							>
								<img
									className="mx-auto my-2"
									src={ProLogo}
									alt=""
									style={{ maxHeight: "90px", maxWidth: "90px" }}
								/>
							</div>
							<p>Price from</p>
							<h4>
								£{card2.priceFrom * numberOfDesksCalculator} to £
								{card2.priceTo * numberOfDesksCalculator}
							</h4>
							<Button
								variant="outline-dark-blue"
								className="w-100 w-md-auto btn fw-bold rounded-pill mt-4 px-lg-4 py-2"
								onClick={() =>
									handleFormShow(
										card2.heading,
										card2.priceFrom * numberOfDesksCalculator,
										card2.priceTo * numberOfDesksCalculator
									)
								}
							>
								Request tailored report
							</Button>
						</div>
					</Col>
					<Col md={4}>
						<div
							style={{
								borderRadius: "14px",
								border: "1px solid #E49400",
								background: "#F28B2D26",
							}}
							className="card p-3 px-md-2 text-center px-lg-3"
						>
							<h3 className="text-card-yellow fs-3">{card3.heading}</h3>
							<div
								style={{ height: "90px" }}
								className="d-flex justify-content-center align-items-center"
							>
								<img
									className="mx-auto my-2"
									src={ExecLogo}
									alt=""
									style={{ maxHeight: "90px", maxWidth: "90px" }}
								/>
							</div>
							<p>Price from</p>
							<h4>
								£{card3.priceFrom * numberOfDesksCalculator} to £
								{card3.priceTo * numberOfDesksCalculator}
							</h4>
							<Button
								variant="outline-dark-blue"
								className="w-100 w-md-auto btn fw-bold rounded-pill mt-4 px-lg-4 py-2"
								onClick={() =>
									handleFormShow(
										card3.heading,
										card3.priceFrom * numberOfDesksCalculator,
										card3.priceTo * numberOfDesksCalculator
									)
								}
							>
								Request tailored report
							</Button>
						</div>
					</Col>
				</Row>
				<Row className="text-center mt-4">
					<Col>
						{ctaButton?.url !== null && ctaButton?.url?.length > 0 && (
							<Link
								className="w-100 w-md-auto btn fw-bold rounded-pill mt-md-5 mt-4 text-white bg-primary px-4 py-2 mb-4"
								to={ctaButton.url}
							>
								{ctaButton.title || "View Offices"}
							</Link>
						)}
					</Col>
				</Row>
			</Container>
		</Layout>
	);
};

export default CalculatorResultsPage;

export const pageQuery = graphql`
	query CalcLocationById($id: String!) {
		wpCalculatorLocation(id: { eq: $id }) {
			title
			slug
			calculatorLocationFields {
				shortLocationName
				ctaButton {
					url
					title
				}
				card3 {
					heading
					priceFrom
					priceTo
				}
				card2 {
					heading
					priceFrom
					priceTo
				}
				card1 {
					heading
					priceFrom
					priceTo
				}
			}
		}

		site {
			siteMetadata {
				siteUrl
			}
		}
	}
`;
